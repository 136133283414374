import React from "react";
import { useNavigate } from "react-router-dom";

const PrivacyPolicy = () => {
  const navigate = useNavigate();

  const triggerPrivayPolicyBox = () => {
    navigate(-1);
  };
  return (
    <>
      {
        <div id="privacyPolicyBox" className="app-popup-main-container">
          <div className="app-popup-container">
            <h1 className="app-popup-content-heading">Privacy Policy</h1>
            <div className="app-popup-content-container">
              <p className="app-popup-text-highlight-text">
                For the Users / Users under Vendors, Importers and all entities
                using the Clinical API.
              </p>
              <p className="app-popup-text-highlight-text">DO NOT -</p>
              <ul className="app-popup-list-container">
                <li>
                  Access or use the Clinical AI APIs, Documentation, or
                  associated cloud services except as expressly permitted by the
                  Clinical AI subscription and distribution agreement.
                </li>
                <li>
                  Decompile, disassemble, or reverse engineer or attempt to
                  discover any source code or algorithms included in, or
                  accessed by, the Clinical AI APIs or associated cloud
                  services.
                </li>
                <li>
                  Hamper with or misuse the technical delivery systems used in
                  connection with the Clinical AI Tool or associated cloud
                  services.
                </li>
                <li>
                  Other than as expressly permitted by Agreement, copy, edit,
                  reproduce, modify, distribute, transmit, sell, resell,
                  display, perform, make translations, or other derivative works
                  of the Clinical AI Tools, Documentation, associated cloud
                  services or any data or other information made available
                  hereunder or via the Clinical AI APIs &#38; Services
                </li>
                <li>
                  Introduce any Viruses into the Clinical AI APIs or Associated
                  cloud services.
                </li>
                <li>
                  Interfere with, or attempt to interfere with, the Clinical AI
                  APIs or the associated cloud services or the lawful access to
                  or use of the Clinical AI APIs or associated cloud services by
                  any other Person.
                </li>
                <li>
                  Alter, remove, obscure, or change the location, content, or
                  appearance of any disclaimers, notices of copyright,
                  trademark, service mark, or other proprietary rights, or other
                  notices included in the Clinical AI tools, Documentation, or
                  Associated cloud services.
                </li>
                <li>
                  Violate any applicable Law in connection with this Agreement
                  or use the Clinical AI APIs and Technology (including any data
                  or other information obtained in connection therewith), or
                  associated cloud services in a way that violates applicable
                  Law or infringes the intellectual property rights of any third
                  party.
                </li>
              </ul>
            </div>
            <div className="app-popup-close-container">
              <button
                className="app-popup-close-button"
                type="button"
                onClick={triggerPrivayPolicyBox}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      }
    </>
  );
};

export default PrivacyPolicy;
