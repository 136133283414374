import React, { useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import AICVDRoutes from "./components/projects/aicvd";
import LiverRoutes from "./components/projects/liver";
import PrediabetesRoutes from "./components/projects/prediabetes";
import { PatientProvider } from "./context/PatientContext";
import ProtectedRoute from "./components/ProtectRouter";
import Cookies from "js-cookie";
import LandingPage from "./components/LandingPage";
import Authentication from "./components/Login";
import PrivacyPolicy from "./components/PrivacyPolicy";

const App = () => {
  const [token, setToken] = useState(Cookies.get("jwt_token"));

  const updateToken = (token) => {
    setToken(token);
  };

  return (
    <PatientProvider>
      <Router>
        <Routes>
          <Route
            path="/login"
            element={<Authentication updateToken={updateToken} />}
          />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route
            path="/"
            element={
              token ? (
                <LandingPage />
              ) : (
                <Authentication updateToken={updateToken} />
              )
            }
          />
          <Route
            path="/aicvd/*"
            element={<ProtectedRoute app="aicvd" element={AICVDRoutes} />}
          />
          <Route
            path="/prediabetes/*"
            element={
              <ProtectedRoute app="prediabetes" element={PrediabetesRoutes} />
            }
          />
          <Route
            path="/liver/*"
            element={<ProtectedRoute app="liver" element={LiverRoutes} />}
          />
        </Routes>
      </Router>
    </PatientProvider>
  );
};

export default App;
