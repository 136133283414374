import React, { useEffect, useContext, useState, useRef } from "react";
import Cookies from "js-cookie";
import FieldsContext from "../../../context/FieldsContext";
import { useNavigate } from "react-router-dom";
import { Bars } from "react-loader-spinner";
import "./index.css";

const AppLandingPage = (props) => {
  const { setFields } = useContext(FieldsContext);
  const [current_app] = useState(props.app);
  const [landingPage, setLandingPage] = useState(null);
  const navigate = useNavigate();
  const aboutRef = useRef();
  const validRef = useRef();
  const researchRef = useRef();
  const TakeMetoAppRef = useRef();
  const [termsofuse, setTermsOfUse] = useState(false);
  const scrollTo = (ref) => {
    ref.current.scrollIntoView({ behavior: "smooth" });
  };
  useEffect(() => {
    const fetchData = async () => {
      const url = props.LANDING_URL;
      const jwt_token = Cookies.get("jwt_token");
      let myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", `Bearer ${jwt_token}`);

      const options = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      try {
        const response = await fetch(url, options);
        const responseObj = await response.json();
        if (responseObj.status === "success") {
          setFields(responseObj.data);
          setLandingPage(responseObj.data.landing_page);
          localStorage.setItem("fields", JSON.stringify(responseObj.data));
        } else {
          alert("Failed to fetch the data");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [setFields, navigate, props.LANDING_URL, props.app, current_app]);

  const logOut = () => {
    Cookies.remove("jwt_token");
    navigate("/login");
  };

  const triggerTermsBox = () => {
    setTermsOfUse(!termsofuse);
  };

  const triggerPrivayPolicyBox = () => {
    navigate("/privacy-policy");
  };

  const returnToHome = () => {
    navigate("/");
  };
  return landingPage ? (
    <>
      <nav id="navBar">
        <div id="logoContainer" className="imageContainer">
          <img src={landingPage[0].apollo_logo} alt="" srcSet="" />
        </div>
        <ul id="quickNav">
          <li onClick={returnToHome}>Home</li>
          <li onClick={() => scrollTo(aboutRef)}>About</li>
          <li onClick={() => scrollTo(validRef)}>Validation</li>
          <li onClick={() => scrollTo(researchRef)}>The Research</li>
          <li>FAQ</li>
          <li onClick={() => scrollTo(TakeMetoAppRef)}>App</li>
        </ul>
        <div className="landing-navigation-buttons">
          <button id="LogoOutButton_PDPage" type="button" onClick={logOut}>
            Log Out
          </button>
        </div>
        <div id="quickMobileNav"></div>
      </nav>
      <main>
        <div id="coverImageContainer" className="imageContainer">
          <img src={landingPage[0].img} alt="img" />
        </div>
        <div ref={aboutRef} id="aboutContainer" className="container">
          <div id="abutImageContainer" className="imageContainer mobileImg">
            <img src={landingPage[1].img} alt="img" />
          </div>
          <div id="aboutText" className="textConent">
            <ul id="aboutPoints" className="points">
              <h3 id="aboutHeading">{landingPage[1].heading}</h3>
              {landingPage[1].points.map((point) => {
                return <li key={point}>{point}</li>;
              })}
            </ul>
          </div>
          <div id="abutImageContainer" className="imageContainer desktopimg">
            <img src={landingPage[1].img} alt="img" />
          </div>
        </div>
        <div id="Validation" ref={validRef} className="container">
          <div id="validationImageContainer" className="imageContainer">
            <img src={landingPage[2].img} alt="img" />
          </div>
          <div id="validationTextcontent" className="textConent">
            <ul
              id="validationPoints"
              className={`points ${
                landingPage[2].points.length > 5 ? "bigPoints" : ""
              }`}
            >
              <h3 id="aboutHeading">{landingPage[2].heading}</h3>
              {landingPage[2].points.map((point) => {
                return <li key={point}>{point}</li>;
              })}
            </ul>
          </div>
        </div>
        <div ref={researchRef} id="Research" className="textContent">
          <ul className="points ">
            <h3 id="aboutHeading">{landingPage[3].heading}</h3>
            {landingPage[3].points.map((point) => {
              return <li key={point}>{point}</li>;
            })}
          </ul>
        </div>
        <div id="FAQContainert" className="container">
          <div id="FaqTextcontent" className="textConent">
            <ul id="faqPoints" className="points">
              <h3 id="faqHeading">{landingPage[4].heading}</h3>
              {landingPage[4].points.map((point) => {
                return <li key={point}>{point}</li>;
              })}
            </ul>
            <p>{landingPage[4].content}</p>
          </div>
          <div id="FaqImageContainer" className="imageContainer">
            <img src="apollo_certificate_MD 763515.jpg" alt="img" />
          </div>
        </div>
        <div ref={TakeMetoAppRef} className="container take-me-to-app">
          <button
            id="TakeToMeApp"
            type="button"
            onClick={() => {
              navigate(`/${current_app}/patient-details`);
            }}
          >
            Take me to App
          </button>
          <div className="checkbox-with-text">
            <input type="checkbox" className="checkbox" id="loginCheckBox" />
            <label htmlFor="loginCheckBox" className="checkbox-text">
              I Accept the{" "}
              <button
                type="button"
                className="new-user-link-tag"
                href="#"
                onClick={triggerTermsBox}
              >
                Terms of use
              </button>
            </label>
          </div>
        </div>
      </main>
      {props.app === "aicvd" && termsofuse && (
        <div id="aicvd-termsBox" className="app-popup-main-container">
          <div className="app-popup-container">
            <h1 className="app-popup-content-heading">Terms of Use</h1>
            <div className="app-popup-content-container">
              <p className="app-popup-text">
                By agreeing to use the AICVD Application, I have been made aware
                of the hardware and software requirements that I will need to
                set up from my side for a smooth and uninterrupted flow of the
                Application Programming Interface (API). I undertake to abide by
                all applicable laws and regulations for the safe and ethical
                delivery of medical services through this platform and shall
                adhere by the protocols and standard of care as required. The
                information that I shared with Apollo Hospitals’ AICVD API, is
                true to my knowledge and belief and I will be responsible for
                any legal liability that may arise from in-appropriation of any
                personal or professional information shared or entered in the
                API. I accept that I shall be solely responsible for the medical
                advice and recommendations prescribed post consultation with
                patients via the AICVD App. and any liability that may arise
                therefrom. I have understood the following disclaimers
                associated with the use of the AICVD API
              </p>
              <ul className="app-popup-list-container">
                <li>
                  This is not a diagnostic tool and it does not guarantee the
                  accuracy of the result and cannot be independently acted upon.
                </li>
                <li>
                  This Risk score and Clinical Algorithm is a general guideline
                  for Physicians. Any additional laboratory investigations,
                  Diagnostic Imaging, Treatment or Patient Education related to
                  lifestyle management is under the Physician’s or
                  Cardiologist’s discretion.
                </li>
                <li>
                  To ensure the information in the report is up to date,
                  accurate and correct, Doctor shall be consulted for
                  interpretation of the report. He / She shall also ensure that
                  the entries made in the API are correct.
                </li>
                <li>
                  Apollo Hospitals and its Staff does not offer any assurance on
                  the information made available or shall be liable for any loss
                  or damage as the said report is based on the AICVD Cardiac
                  Risk Score without any intervention from their side.
                </li>
                <li>
                  By usage of AICVD Cardiac Risk Score, it is deemed that the
                  beneficiary of this service has agreed to get the same done at
                  his/her own risk and further agrees with this disclaimer
                  without any limitation or any clauses or sub-clauses.{" "}
                </li>
              </ul>
              <p className="app-popup-text">
                Confidentiality: Each Party hereby undertakes that it will not
                disclose the Confidential Information of the other party, except
                to its affiliates, employees, and/or agents who need to know
                such Confidential Information for the purpose of performance of
                obligations hereunder, provided they have agreed in writing to
                keep such information confidential and to not use such
                information for any unauthorized purposes. The recipient of
                Confidential Information may disclose Confidential Information
                as required under Applicable Laws, after giving to the discloser
                of such Confidential Information, reasonable notice and the
                opportunity to seek confidential treatment by way of a
                protective order or similar remedies or relief prior to
                disclosure, in each case if permitted by applicable Law. Except
                as set forth in this Agreement, neither Party will issue any
                public announcement.
              </p>
            </div>
            <div className="app-popup-close-container">
              <button
                className="app-popup-close-button"
                type="button"
                onClick={triggerTermsBox}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}
      {props.app === "liver" && termsofuse && (
        <div id="aicvd-termsBox" className="app-popup-main-container">
          <div className="app-popup-container">
            <h1 className="app-popup-content-heading">Terms of Use</h1>
            <div className="app-popup-content-container">
              <p className="app-popup-text">
                By agreeing to use the AI Liver Fibrosis Application, I have
                been made aware of the hardware and software requirements that I
                will need to set up from my side for a smooth and uninterrupted
                flow of the Application Programming Interface (API). I undertake
                to abide by all applicable laws and regulations for the safe and
                ethical delivery of medical services through this platform and
                shall adhere by the protocols and standard of care as required.
                The information that I shared with Apollo Hospitals’ AI-LF API,
                is true to my knowledge and belief and I will be responsible for
                any legal liability that may arise from in-appropriation of any
                personal or professional information shared or entered in the
                API. I accept that I shall be solely responsible for the medical
                advice and recommendations prescribed post consultation with
                patients via the AI-LF App. and any liability that may arise
                therefrom. I have understood the following disclaimers
                associated with the use of the AILF API
              </p>
              <ul className="app-popup-list-container">
                <li>
                  This is not a diagnostic tool and it does not guarantee the
                  accuracy of the result and cannot be independently acted upon.
                </li>
                <li>
                  This Risk score and Clinical Algorithm is a general guideline
                  for Physicians. Any additional laboratory investigations,
                  Diagnostic Imaging, Treatment or Patient Education related to
                  lifestyle management is under the Physician’s or
                  Gastroenterologist discretion.
                </li>
                <li>
                  To ensure the information in the report is up to date,
                  accurate and correct, Doctor shall be consulted for
                  interpretation of the report. He / She shall also ensure that
                  the entries made in the API are correct.
                </li>
                <li>
                  Apollo Hospitals and its Staff does not offer any assurance on
                  the information made available or shall be liable for any loss
                  or damage as the said report is based on the AI Liver Fibrosis
                  Risk Score without any intervention from their side.{" "}
                </li>
                <li>
                  By usage of AI based Liver Fibrosis Risk Score, it is deemed
                  that the beneficiary of this service has agreed to get the
                  same done at his/her own risk and further agrees with this
                  disclaimer without any limitation or any clauses or
                  sub-clauses.
                </li>
              </ul>
              <p className="app-popup-text">
                Confidentiality: Each Party hereby undertakes that it will not
                disclose the Confidential Information of the other party, except
                to its affiliates, employees, and/or agents who need to know
                such Confidential Information for the purpose of performance of
                obligations hereunder, provided they have agreed in writing to
                keep such information confidential and to not use such
                information for any unauthorized purposes. The recipient of
                Confidential Information may disclose Confidential Information
                as required under Applicable Laws, after giving to the discloser
                of such Confidential Information, reasonable notice and the
                opportunity to seek confidential treatment by way of a
                protective order or similar remedies or relief prior to
                disclosure, in each case if permitted by applicable Law. Except
                as set forth in this Agreement, neither Party will issue any
                public announcement.
              </p>
            </div>
            <div className="app-popup-close-container">
              <button
                className="app-popup-close-button"
                type="button"
                onClick={triggerTermsBox}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}
      {props.app === "prediabetes" && termsofuse && (
        <div id="aicvd-termsBox" className="app-popup-main-container">
          <div className="app-popup-container">
            <h1 className="app-popup-content-heading">Terms of Use</h1>
            <div className="app-popup-content-container">
              <p className="app-popup-text">
                By agreeing to use the Prediabetes AI Application, I have been
                made aware of the hardware and software requirements that I will
                need to set up from my side for a smooth and uninterrupted flow
                of the Application Programming Interface (API). I undertake to
                abide by all applicable laws and regulations for the safe and
                ethical delivery of medical services through this platform and
                shall adhere by the protocols and standard of care as required.
                The information that I shared with Apollo Hospitals’ Prediabetes
                AI API, is true to my knowledge and belief and I will be
                responsible for any legal liability that may arise from
                in-appropriation of any personal or professional information
                shared or entered in the API. I accept that I shall be solely
                responsible for the medical advice and recommendations
                prescribed post consultation with patients via the Prediabetes
                AI App. and any liability that may arise therefrom. I have
                understood the following disclaimers associated with the use of
                the Prediabetes AI API
              </p>
              <ul className="app-popup-list-container">
                <li>
                  This is not a diagnostic tool and it does not guarantee the
                  accuracy of the result and cannot be independently acted upon.
                </li>
                <li>
                  This Risk score and Clinical Algorithm is a general guideline
                  for Physicians. Any additional laboratory investigations,
                  Diagnostic Imaging, Treatment or Patient Education related to
                  lifestyle management is under the Physician’s, Endocrinologist
                  or Diabetologist discretion.{" "}
                </li>
                <li>
                  To ensure the information in the report is up to date,
                  accurate and correct, Doctor shall be consulted for
                  interpretation of the report. He / She shall also ensure that
                  the entries made in the API are correct.
                </li>
                <li>
                  Apollo Hospitals and its Staff does not offer any assurance on
                  the information made available or shall be liable for any loss
                  or damage as the said report is based on the AI based
                  Prediabetes Risk Score without any intervention from their
                  side.
                </li>
                <li>
                  By usage of Prediabetes AI Risk Score, it is deemed that the
                  beneficiary of this service has agreed to get the same done at
                  his/her own risk and further agrees with this disclaimer
                  without any limitation or any clauses or sub-clauses.
                </li>
              </ul>
              <p className="app-popup-text">
                Confidentiality: Each Party hereby undertakes that it will not
                disclose the Confidential Information of the other party, except
                to its affiliates, employees, and/or agents who need to know
                such Confidential Information for the purpose of performance of
                obligations hereunder, provided they have agreed in writing to
                keep such information confidential and to not use such
                information for any unauthorized purposes. The recipient of
                Confidential Information may disclose Confidential Information
                as required under Applicable Laws, after giving to the discloser
                of such Confidential Information, reasonable notice and the
                opportunity to seek confidential treatment by way of a
                protective order or similar remedies or relief prior to
                disclosure, in each case if permitted by applicable Law. Except
                as set forth in this Agreement, neither Party will issue any
                public announcement.
              </p>
            </div>
            <div className="app-popup-close-container">
              <button
                className="app-popup-close-button"
                type="button"
                onClick={triggerTermsBox}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}
      <footer id="footer" className="container footer-block">
        <p className="">
          © 2024 Apollo Hospitals Enterprise Ltd, All Rights Reserved
        </p>
        <div className="footer-button-container">
          <button
            className="footer-click privacy-button"
            onClick={triggerPrivayPolicyBox}
          >
            Privacy Policy
          </button>
          {props.app === "aicvd" && (
            <a
              className="footer-click"
              href="001-IFU - FAQ AICVD V1.1.pdf"
              target="_blank"
            >
              FAQ
            </a>
          )}
          {props.app === "liver" && (
            <a
              className="footer-click"
              href="001-IFU - FAQ AILF 1.1.pdf"
              target="_blank"
            >
              FAQ
            </a>
          )}
          {props.app === "prediabetes" && (
            <a
              className="footer-click"
              href="001-IFU - FAQ Prediabetes v1.1.pdf"
              target="_blank"
            >
              FAQ
            </a>
          )}

          <a
            className="footer-click"
            href="mailto:clinicalailabs@apollohospitals.com"
            style={{ marginLeft: "10px" }}
          >
            Request Access
          </a>
        </div>
      </footer>
    </>
  ) : (
    <div className="app-loading-container">
      <div className="login-loader-container">
        <Bars
          height="60"
          width="60"
          color="#196983"
          ariaLabel="bars-loading"
          visible={true}
        />
      </div>
    </div>
  );
};

export default AppLandingPage;
